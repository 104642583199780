export const pageSize = 50

export const currentYear = 2024

export const ENV = process.env.REACT_APP_ENV as 'prod' | 'stg' | 'local'

if (ENV !== 'prod' && ENV !== 'stg' && ENV !== 'local') {
  console.warn(
    'WARNING: The environment variable REACT_APP_ENV is invalid! REACT_APP_ENV:',
    ENV,
  )
}

export const LOCAL_STORAGE_LANGUAGE_KEY = 'lg'
export const LOCAL_STORAGE_POPUP = 'popup'

const params =
  typeof window !== 'undefined' ? new URL(document.URL).searchParams : undefined

export type TLang = 'en' | 'ja'

export let langParam: null | string | undefined = params?.get(
  LOCAL_STORAGE_LANGUAGE_KEY,
)

if (langParam !== 'en' && langParam !== 'ja') {
  langParam = null
}

export const now = new Date()
export const thisYear = now.getFullYear()
export const copyrightYear = thisYear
export const lastMonth = now.getMonth()
export const thisMonth = lastMonth + 1

export const globalInputKeys = [
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'enduranceRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
] as const

/**
 * App version for both the school and the students app.
 */
export const APP_VERSION = `${process.env.REACT_APP_COMMIT_SHA?.substring(
  0,
  7,
)}.${currentYear}.${ENV}`

export type TUserGender = 'MALE' | 'FEMALE'

export type SchoolCategoryCode = 'B1' | 'C1' | 'D1'

export enum VIDEO_TYPE {
  PUBLIC = 'PUBLIC',
  ADVISE = 'ADVISE',
  TEST_VIDEO = 'TEST_VIDEO',
}

export const API_URL = process.env.NEXT_PUBLIC_API_URL

export const isProduction = !!API_URL?.includes(
  'https://api-v2.alpha-pestalozzi.com',
)

export const roundDecimal = (v: number, d: number) =>
  (Math.round(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d)

export const getLastIndex = (arr: any[], fn: (t: any) => boolean) => {
  let result = -1
  for (let index = 0; index < arr.length; index++) {
    if (fn(arr[index])) result = index
  }
  return result
}